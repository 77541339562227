import { trackGAEvent } from 'utils/GA/index';

class ActivityTrackerService {
  sendEvent = async (activity) => {
    try {
      trackGAEvent(
        activity.action,
        activity.referenceId,
        activity.referenceValue,
      );
    } catch (error) {
      console.log('track', error);
    }
  };
}

export default new ActivityTrackerService();
