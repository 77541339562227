import { get, last, first } from 'lodash';

import { DEFAULT_FORMAT } from './constants';

function toStep(apiStep, apiHotspot, apiGuidedTour) {
  const step = {
    format: get(apiGuidedTour, 'format', DEFAULT_FORMAT),
    imageUrl: apiStep.imageUrl,
    width: apiHotspot.width,
    height: apiHotspot.height,
    x: apiHotspot.x,
    y: apiHotspot.y,
    event: apiHotspot.event,
    description: apiHotspot.description,
    descriptionWidth: apiHotspot.descriptionWidth,
    descriptionHeight: apiHotspot.descriptionHeight,
    placement: apiHotspot.placement,
    id: apiStep.id,
    transitionTime: apiHotspot.transitionTime,
    header: apiHotspot.header,
    footer: apiHotspot.footer,
  };

  return step;
}

function toFlowList(apiGuidedTour) {
  const flowList = get(apiGuidedTour, 'flowIds', {}).map((flowKey) => {
    const apiFlow = apiGuidedTour.flows[flowKey];
    let stepList = get(apiFlow, 'stepIds', []).map((stepId) => {
      const apiStep = apiGuidedTour.steps[stepId];
      const apiHotspot = get(
        apiGuidedTour,
        `hotspots[${apiStep.hotspotId}]`,
        {},
      );
      const step = toStep(apiStep, apiHotspot, apiGuidedTour);

      return step;
    });

    const conclusion = apiFlow.conclusion || undefined;
    const lastItem = last(stepList);
    const newLastStep = {
      ...lastItem,
      description: conclusion,
      type: 'conclusion',
      title: 'Final Thoughts',
      actionLabel: 'Restart Tour',
    };

    stepList.push(newLastStep);

    const intro =
      apiFlow.intro || 'Welcome, click the hotspot to start the "Tour"';
    const firstItem = first(stepList);
    const newFirstStep = {
      ...firstItem,
      description: apiFlow.intro || intro,
      type: 'introduction',
      title: 'Intro',
      actionLabel: 'Start Tour',
    };
    stepList = [newFirstStep].concat(stepList);

    const flow = {
      id: apiFlow.id,
      name: apiFlow.name,
      steps: stepList,
      arrowNavigation: !!apiFlow.arrowNavigation,
    };

    return flow;
  });

  return flowList;
}

function toTourReader(serverTour) {
  const apiGuidedTour =
    (serverTour.props && serverTour.props.tour) || serverTour.tour;
  const publishedDemoId = serverTour.id;
  const flowList = apiGuidedTour.flowIds ? toFlowList(apiGuidedTour) : [];

  const tourReader = {
    flowList,
    publishedDemoId,
  };

  return tourReader;
}

export default toTourReader;
