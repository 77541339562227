import React from 'react';
import { number, string } from 'prop-types';
import toMarvelEmbedUrl from './toMarvelEmbedUrl';
import './styles.scss';

function MarvelIframe({ name, url }) {
  return (
    <div className="marvel-iframe-wrapper">
      {url && (
        <iframe
          className="marvel-iframe"
          src={toMarvelEmbedUrl(url)}
          title={name}
          allowTransparency="true"
          frameBorder="0"
        />
      )}
    </div>
  );
}

MarvelIframe.propTypes = {
  name: number.isRequired,
  url: string.isRequired,
};

export default MarvelIframe;
