import axios from 'axios';
import appConfig from 'app/Config';

const baseURL = appConfig.get('demoLibraryURL');

function DemoApiClient({ url, method, body }) {
  const fullUrl = `${baseURL}${url}`;
  const httpParams = [fullUrl];
  if (body) {
    httpParams.push(body);
  }
  return axios[method](...httpParams).then();
}

export default DemoApiClient;
