import React from 'react';
import ReactDOM from 'react-dom';

import './styles.scss';

import * as serviceWorker from 'serviceWorker';
import Main from 'app/Main';

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
