import { useState, useEffect } from 'react';
import { get } from 'lodash';

import DemoApiClient from 'utils/DemoApiClient';
import { RESULT } from './constants';

function useLoadExperience(id) {
  const [data, setData] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const setTourResult = (tour) => {
    if (get(tour, 'enabled')) {
      setResult(RESULT.LOADED);
    } else {
      setResult(RESULT.DISABLED);
    }
  };

  const loadExperience = async () => {
    try {
      const response = await DemoApiClient({
        url: `experiences/${id}`,
        method: 'get',
      });
      setData(response.data);
      setTourResult(response.data);
    } catch (responseError) {
      setError({ code: get(responseError, 'response.status') });
    } finally {
      setIsReady(true);
    }
  };

  useEffect(() => {
    loadExperience();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    error,
    isReady,
    result,
  };
}

export default useLoadExperience;
