/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState, useRef, useEffect } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import {
  ChatSquareDotsFill,
  StopwatchFill,
  Broadcast as GuideIcon,
} from 'react-bootstrap-icons';
import cx from 'classnames';
import { noop } from 'lodash';
import useToggleState from 'utils/useToggleState';

import ClickOutside from 'common/ClickOutside';

import themecss from './theme';
import convertToPixel from './convertToPixel';

import './styles.scss';

const CLICK_EVENT = 'click';
const COMMENTARY_EVENT = 'commentary';
const TIMER_EVENT = 'timer';

const isTimer = (status) => status === TIMER_EVENT;
const ARROW_SIZE = 10;

function Hotspot({
  format,
  parentDimension,
  active,
  onClick,
  description,
  descriptionHeight,
  descriptionWidth,
  event,
  height,
  isMobile,
  placement,
  width,
  x,
  y,
  transitionTime,
  header,
  footer,
}) {
  const [show, setShow] = useState(false);
  const highlightToggle = useToggleState(false);
  const containerRef = useRef(null);
  const targetRef = useRef(null);
  const scrollToHotspot = () => {
    containerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  useEffect(() => {
    setShow(active);
    scrollToHotspot();
  }, [active]);

  const theme = useMemo(() => {
    return themecss(format);
  }, [format]);

  useEffect(() => {
    let timerTimeoutId;
    if (isTimer(event)) {
      timerTimeoutId = setTimeout(() => {
        onClick();
      }, transitionTime * 1000);
    }

    return () => {
      clearTimeout(timerTimeoutId);
    };
  }, []);

  const converted = active
    ? convertToPixel(parentDimension, { x, y, height, width })
    : {};

  const handleClickOutside = () => {
    highlightToggle.turnOn();
    setTimeout(() => {
      highlightToggle.turnOff();
    }, 400);
  };

  const eventTypeHandler = useMemo(() => {
    const HANDLER_MAP = {
      [CLICK_EVENT]: {
        onClickOutside: handleClickOutside,
        onClick,
      },
      [COMMENTARY_EVENT]: {
        onClickOutside: onClick,
        onClick,
      },
      [TIMER_EVENT]: {
        onClickOutside: noop,
        onClick: noop,
      },
    };

    return HANDLER_MAP[event] || HANDLER_MAP[CLICK_EVENT];
  }, [event]);

  const eventIcon = useMemo(() => {
    const ICON_MAP = {
      [CLICK_EVENT]: null,
      [COMMENTARY_EVENT]: <ChatSquareDotsFill className="pr-2 hotspot-icon" />,
      [TIMER_EVENT]: <StopwatchFill className="pr-2 hotspot-icon" />,
    };

    return ICON_MAP[event];
  }, [event]);
  // check boundaries collision/overflow out of boundaries
  return (
    <>
      <style type="text/css">{theme}</style>
      <ClickOutside onClickOutside={eventTypeHandler.onClickOutside}>
        <div
          ref={containerRef}
          className={cx(
            'hotspot-wrapper',
            active && 'active',
            isMobile && 'mobile',
          )}
          style={{
            position: 'absolute',
            top: converted.y,
            left: converted.x,
            height: converted.height,
            width: converted.width,
            zIndex: '99999',
          }}
          onClick={eventTypeHandler.onClick}
        >
          <div
            ref={targetRef}
            onMouseEnter={event === CLICK_EVENT && highlightToggle.turnOn}
            onMouseLeave={event === CLICK_EVENT && highlightToggle.turnOff}
            style={{
              height: converted.height,
            }}
            className={cx(
              'hotspot rounded',
              event === CLICK_EVENT && 'click',
              highlightToggle.isActive && 'highlight',
            )}
          >
            {event === CLICK_EVENT && (
              <GuideIcon
                className={cx(
                  'guide-icon',
                  highlightToggle.isActive && 'active',
                )}
              />
            )}
            {description && (
              <Overlay
                show={show}
                target={targetRef.current}
                placement={placement}
                container={containerRef.current}
              >
                <Popover
                  transition
                  className={cx('shadow', isMobile && 'mobile')}
                >
                  <Popover.Content>
                    <div
                      clasName="popover-content"
                      style={{
                        width: descriptionWidth,
                        height:
                          descriptionHeight && descriptionHeight - ARROW_SIZE,
                      }}
                    >
                      <div
                        onMouseEnter={
                          event === CLICK_EVENT && highlightToggle.turnOn
                        }
                        onMouseLeave={
                          event === CLICK_EVENT && highlightToggle.turnOff
                        }
                      >
                        <div className="hotspot-header pb-1">
                          {eventIcon}
                          {header || 'Step'}
                        </div>

                        <div
                          className="hotspot-description"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: description }}
                        />

                        {footer && (
                          <div className="hotspot-footer py-1">{footer}</div>
                        )}
                        </div>
                    </div>
                  </Popover.Content>
                </Popover>
              </Overlay>
            )}
          </div>
        </div>
      </ClickOutside>
    </>
  );
}

Hotspot.propTypes = {
  active: bool,
  description: string,
  descriptionHeight: number,
  descriptionWidth: number,
  event: string,
  footer: string,
  format: shape({}).isRequired,
  header: string,
  height: string,
  isMobile: bool,
  onClick: func.isRequired,
  parentDimension: shape({
    height: number,
    width: number,
  }).isRequired,
  placement: string,
  transitionTime: number,
  width: string,
  x: string,
  y: string,
};

Hotspot.defaultProps = {
  active: false,
  description: null,
  descriptionHeight: undefined,
  descriptionWidth: undefined,
  event: CLICK_EVENT,
  footer: null,
  header: null,
  height: '0%',
  isMobile: false,
  placement: 'top',
  transitionTime: 2,
  width: '0%',
  x: '0',
  y: '0',
};

export default Hotspot;
