const Texts = {
  '404Title': 'Experience not found.',
  '404Message': 'The experience tour you are looking for does not exist.',
  '500Title': 'There has been an error.',
  '500Message':
    'A server error happened and the experience could not be loaded.',
  DISABLEDTitle: 'Experience is disabled.',
  DISABLEDMessage: 'The experience tour you are looking for is disabled.',
  serverConnErrorTitle: 'Can not reach the server.',
  serverConnErrorMessage: 'The experience tour could not be loaded.',
  generalErrorTitle: 'An error occurred.',
  generalErrorMessage:
    'An error occurred and the experience could not be loaded.',
};

export default Texts;
