import { useEffect, useMemo, useRef } from 'react';
import ActivityTrackerService from 'app/Services/ActivityTrackerService';
import { TourActivity } from './constants';
import { isIntro, isConclusion } from './utils/step';

function useTrackActivity(enabled, tour, tourData) {
  const tourStartTime = useRef(Date.now());
  const stepStartTime = useRef(Date.now());
  const currentStep = useMemo(() => {
    const step =
      tour.stepIndex > -1 ? tour.activeFlow.steps[tour.stepIndex] : null;
    return step;
  }, [tour.activeFlow.steps, tour.stepIndex]);

  const prevStep = useMemo(() => {
    const prevIndex = tour.stepIndex - 1;
    const step = prevIndex > -1 ? tour.activeFlow.steps[prevIndex] : null;
    return step;
  }, [tour.activeFlow.steps, tour.stepIndex]);

  const getActivity = (action, referenceId, referenceValue) => ({
    action,
    referenceId,
    referenceValue,
  });

  const trackFlowLoaded = () => {
    const activity = getActivity(TourActivity.FLOW_LOADED, tourData.id);
    ActivityTrackerService.sendEvent(activity);
  };

  const trackFlowCompleted = () => {
    const totalTourMs = Date.now() - tourStartTime.current;
    const activity = getActivity(
      TourActivity.FLOW_COMPLETED,
      tourData.id,
      totalTourMs,
    );

    ActivityTrackerService.sendEvent(activity);
  };

  const trackIntroStarted = () => {
    const activity = getActivity(TourActivity.INTRO_STARTED, tourData.id);
    ActivityTrackerService.sendEvent(activity);
  };

  const trackIntroCompleted = () => {
    const totalIntroMs = Date.now() - tourStartTime.current;
    const activity = getActivity(
      TourActivity.INTRO_COMPLETED,
      tourData.id,
      totalIntroMs,
    );

    ActivityTrackerService.sendEvent(activity);
  };

  const trackStepStarted = () => {
    if (!currentStep) return;

    const activity = getActivity(TourActivity.STEP_STARTED, currentStep.id);
    ActivityTrackerService.sendEvent(activity);
    stepStartTime.current = Date.now();
  };

  const trackStepCompleted = () => {
    if (!prevStep) return;

    const stepTotalMs = Date.now() - stepStartTime.current;
    const activity = getActivity(
      TourActivity.STEP_COMPLETED,
      prevStep.id,
      stepTotalMs,
    );
    ActivityTrackerService.sendEvent(activity);
  };

  const track = () => {
    if (isIntro(currentStep) && !prevStep) {
      trackIntroStarted();
    } else if (isConclusion(currentStep)) {
      trackStepCompleted();
      trackFlowCompleted();
    } else if (isIntro(prevStep)) {
      trackIntroCompleted();
      trackStepStarted();
    } else {
      trackStepCompleted();
      trackStepStarted();
    }
  };

  useEffect(() => {
    if (enabled) {
      trackFlowLoaded();
    }
  }, []);

  useEffect(() => {
    if (enabled) {
      track();
    }
  }, [currentStep, prevStep]);
}

export default useTrackActivity;
