import React from 'react';
import { useParams } from 'react-router-dom';
import { string } from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

import GuidedTourView from 'app/Views/GuidedTour';
import useQueryString from 'utils/useQueryString';
import useLoadTour from './useLoadTour';

function TemplatePreviewView({ tourType }) {
  const { id } = useParams();
  const query = useQueryString();
  const tourRequest = useLoadTour(id, tourType);
  const productType = query.get('product_type');
  const showExperience = tourRequest.isReady && tourRequest.data;

  return (
    <div>
      {!tourRequest.isReady && (
        <div className="loading-spinner">
          <Spinner className="text-primary" animation="border" />
        </div>
      )}
      {showExperience && (
        <GuidedTourView
          tourData={tourRequest.data}
          trackActivity={false}
          forceMobile={productType === 'MOBILE'}
        />
      )}
    </div>
  );
}

TemplatePreviewView.propTypes = {
  tourType: string.isRequired,
};

export default TemplatePreviewView;
