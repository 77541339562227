import ReactGA from 'react-ga';
import { trackGAEvent } from 'utils/GA/index';

import Config from 'app/Config';

class ExperienceTrackerService {
  init = () => {
    ReactGA.initialize(Config.get('gaId'), { debug: false });
  };

  track = (id, result) => {
    switch (result) {
      case 'LOADED': {
        const path = window.location.pathname.replace(/\/+$/, '');

        ReactGA.pageview(path);
        trackGAEvent('Loaded', id);
        break;
      }
      case 'DISABLED': {
        trackGAEvent('Loaded disabled tour', id);
        break;
      }
      case 'NOT_FOUND': {
        trackGAEvent('Experience not found', id);
        break;
      }
      default:
        break;
    }
  };
}

export default new ExperienceTrackerService();
