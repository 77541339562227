import React from 'react';
import { bool, string } from 'prop-types';

import ErrorPage from 'common/ErrorPage';
import Texts from './texts';

function ErrorDisplay({ displayCode, errorCode }) {
  return errorCode === undefined ? (
    <ErrorPage
      title={Texts.serverConnErrorTitle}
      message={Texts.serverConnErrorMessage}
    />
  ) : (
    <ErrorPage
      code={displayCode ? `${errorCode}` : ''}
      title={Texts[`${errorCode}Title`] || Texts.generalErrorTitle}
      message={Texts[`${errorCode}Message`] || Texts.generalErrorMessage}
    />
  );
}

ErrorDisplay.propTypes = {
  displayCode: bool,
  errorCode: string,
};

ErrorDisplay.defaultProps = {
  displayCode: true,
  errorCode: undefined,
};

export default ErrorDisplay;
