import React from 'react';
import { string } from 'prop-types';

import './styles.scss';

function ErrorPage({ code, title, message }) {
  return (
    <div className="error-container">
      <div className="center-container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mr-3 pr-3 status-code d-flex align-items-center">
            <h1>{code}</h1>
          </div>
          <div className="status-message">
            <h4 className="mt-1">{title}</h4>
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  code: string,
  message: string.isRequired,
  title: string.isRequired,
};

ErrorPage.defaultProps = {
  code: '',
};

export default ErrorPage;
