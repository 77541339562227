import { useEffect, useMemo } from 'react';
import safeInvoke from 'utils/safeInvoke';
import { isIntro, isConclusion } from './utils/step';

function useTourArrowNavigation(currentFlow, currentStep, handlers) {
  const arrowKeyHandlers = useMemo(() => {
    const HANDLER_MAP = {
      ArrowRight: handlers.right,
      ArrowLeft: handlers.left,
      ArrowUp: handlers.up,
      ArrowDown: handlers.down,
    };

    return HANDLER_MAP;
  }, [handlers]);

  function handleNavigation(e) {
    if (isIntro(currentStep) || isConclusion(currentStep)) {
      return;
    }

    if (e.keyCode >= 37 && e.keyCode <= 40) {
      safeInvoke(arrowKeyHandlers[e.key]);
    }
  }

  useEffect(() => {
    if (currentFlow.arrowNavigation) {
      document.addEventListener('keyup', handleNavigation);
    }

    return () => {
      document.removeEventListener('keyup', handleNavigation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);
}

export default useTourArrowNavigation;
