function toPixel(base, parent) {
  const converted = (base * parent) / 100;
  return converted;
}

function convertToPixel(parentDimensions, coords) {
  if ((coords.width || '').includes('px')) {
    return coords;
  }
  const { width: parentWidth, height: parentHeight } = parentDimensions;
  const { x, y } = coords;
  const xFloat = parseFloat(x);
  const yFloat = parseFloat(y);
  const width = coords.width ? parseFloat(coords.width) : 100;
  const height = coords.height ? parseFloat(coords.height) : 100;
  const convertedWidth = toPixel(width, parentWidth);
  const convertedHeight = toPixel(height, parentHeight);
  const convertedX = toPixel(xFloat, parentWidth);
  const convertedY = toPixel(yFloat, parentHeight);
  const convertedCoords = {
    ...coords,
    x: convertedX,
    y: convertedY,
    width: `${convertedWidth}px`,
    height: `${convertedHeight}px`,
  };
  return convertedCoords;
}

export default convertToPixel;
