import React from 'react';
import { arrayOf, bool, func, string, shape } from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import FlowItem from './components/FlowItem';
import './styles.scss';

function FlowSelection({
  activeFlowId,
  flows,
  tourIsReady,
  step: { actionLabel, description, title },
  selectFlow,
  tourAction,
}) {
  return (
    <div className="flow-selection d-flex">
      <div className="flow-selection-bg" />
      <div className="flow-selection-content w-100">
        <div>
          <div className="mb-4">
            <h2>{title}</h2>
          </div>
          <div
            className="message mb-3"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="d-flex justify-content-center">
            {tourIsReady ? (
              <Button onClick={tourAction}>{actionLabel}</Button>
            ) : (
              <Spinner className="text-primary" animation="border" />
            )}
          </div>
        </div>
      </div>
      {flows && flows.length > 1 && (
        <div className="flow-selection-content w-50">
          {flows.map((flow) => (
            <FlowItem
              flow={flow}
              key={flow.id}
              selectFlow={selectFlow}
              isActive={flow.id === activeFlowId}
            />
          ))}
        </div>
      )}
    </div>
  );
}

FlowSelection.propTypes = {
  activeFlowId: string.isRequired,
  flows: arrayOf(
    shape({
      id: string,
      label: string,
      completed: bool,
    }),
  ).isRequired,
  selectFlow: func.isRequired,
  step: shape({
    actionLabel: string.isRequired,
    description: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  tourAction: func.isRequired,
  tourIsReady: bool.isRequired,
};

export default FlowSelection;
