import { useState, useEffect } from 'react';
import DemoApiClient from 'utils/DemoApiClient';

function useLoadTour(id, tourType) {
  const [data, setData] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(null);

  const loadTour = async () => {
    const path =
      tourType === 'TEMPLATE' ? 'guided_tours_templates' : 'guided_tours';

    try {
      setIsReady(false);
      const response = await DemoApiClient({
        url: `${path}/${id}`,
        method: 'get',
      });
      setData(response.data);
    } catch (responseError) {
      setError(responseError);
    } finally {
      setIsReady(true);
    }
  };

  useEffect(() => {
    loadTour();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    isReady,
    error,
  };
}

export default useLoadTour;
