import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ExperienceView from 'app/Views/Experience';
import TemplatePreviewView from 'app/Views/TemplatePreview';
import ErrorPage from 'common/ErrorPage';

import './styles.scss';

function Main() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/templates/:id">
            <TemplatePreviewView tourType="TEMPLATE" />
          </Route>
          <Route exact path="/tours/:id">
            <TemplatePreviewView tourType="TOUR" />
          </Route>
          <Route exact path="/:id" component={ExperienceView} />
          <Route>
            <ErrorPage
              code="404"
              title="Page not found."
              message="The requested page could not be found."
            />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default hot(module)(Main);
