/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { bool, func, string, shape } from 'prop-types';
import { CheckCircleFill, Circle } from 'react-bootstrap-icons';
import cx from 'classnames';
import './styles.scss';

function FlowItem({ flow, isActive, selectFlow }) {
  const handleClick = () => selectFlow(flow.id);

  return (
    <div
      className={cx(
        'd-flex',
        'mb-4',
        'p-3',
        'flow-item',
        isActive && 'active-flow-item',
      )}
      key={flow.id}
      onClick={handleClick}
    >
      <div className="w-75">
        <h5>{flow.label}</h5>
      </div>
      <div className="d-flex justify-content-end w-25">
        {flow.completed ? <CheckCircleFill size={30} /> : <Circle size={30} />}
      </div>
    </div>
  );
}

FlowItem.propTypes = {
  flow: shape({
    id: string,
    label: string,
    completed: bool,
  }).isRequired,
  isActive: bool,
  selectFlow: func.isRequired,
};

FlowItem.defaultProps = {
  isActive: false,
};

export default FlowItem;
