import React from 'react';
import { node } from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';

import './styles.scss';

function MobileDisplay({ children }) {
  return (
    <div className="mobile-display px-3 py-5 ">
      <ScrollContainer horizontal={false} className="mobile-content rounded">
        {children}
      </ScrollContainer>
    </div>
  );
}

MobileDisplay.propTypes = {
  children: node.isRequired,
};

export default MobileDisplay;
