export const StepType = {
  INTRODUCTION: 'introduction',
  CONCLUSION: 'conclusion',
};

export const TourActivity = {
  FLOW_LOADED: 'FLOW_LOADED',
  INTRO_STARTED: 'INTRO_STARTED',
  INTRO_COMPLETED: 'INTRO_COMPLETED',
  STEP_STARTED: 'STEP_STARTED',
  STEP_COMPLETED: 'STEP_COMPLETED',
  FLOW_COMPLETED: 'FLOW_COMPLETED',
};

export const DEFAULT_FORMAT = {
  color: '#ffffff',
  background: '#8c558e',
  fontFamily: 'arial,helvetica,sans-serif',
  fontSize: '10pt',
};
