import { useEffect, useMemo } from 'react';
import { get, uniqBy } from 'lodash';

const LOADS_PER_ROUND = 5;

function preloadImage(imageURL) {
  // eslint-disable-next-line no-new
  new Promise((resolve, reject) => {
    if (!imageURL) return;
    const img = new Image();
    img.src = imageURL;
    if (!img.complete) {
      img.onload = resolve();
      img.onerror = reject();
    }
  });
}

const getNextBlock = (n) => (n / 2 + 1) * LOADS_PER_ROUND;

// Preloads and stores steps metadata such as images on cache
// next time the image is requested it should be served from disk cache.
function usePreloadSteps(stepIndex, steps) {
  const uniqImages = useMemo(() => {
    return uniqBy(steps, 'imageUrl');
  }, [steps]);

  useEffect(() => {
    if (stepIndex % 2 === 0) {
      const nextBlockEnd = getNextBlock(stepIndex);
      const nextBlockStart = nextBlockEnd - LOADS_PER_ROUND;
      let toLoadIndex = nextBlockStart;
      while (uniqImages[toLoadIndex] && nextBlockEnd - toLoadIndex) {
        preloadImage(get(uniqImages, `[${toLoadIndex}].imageUrl`));
        toLoadIndex += 1;
      }
    }
  }, [stepIndex, uniqImages]);
}

export default usePreloadSteps;
