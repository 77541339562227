import { useState, useEffect } from 'react';
import { find } from 'lodash';
import toTourReader from './TourReader';

function useTour(tourData) {
  const [activeFlow, setActiveFlow] = useState({
    steps: [],
  });
  const [stepIndex, setIndex] = useState(0);
  const [tour, setTour] = useState(null);
  const [flowOptions, setFlowOptions] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const mappedTour = toTourReader(tourData);
    const options = mappedTour.flowList.map((flow, index) => {
      const flowOption = {
        id: flow.id,
        label: flow.name,
        value: index,
        completed: false,
      };
      return flowOption;
    });

    setTour(mappedTour);
    setFlowOptions(options);
    setIsReady(true);
    if (mappedTour.flowList.length) {
      setActiveFlow(mappedTour.flowList[0]);
    }
    setIsReady(true);
  }, []);

  const changeFlow = (newFlowId) => {
    setIndex(0);

    const newActiveFlow = find(tour.flowList, ['id', newFlowId]);
    setActiveFlow(newActiveFlow);
  };

  const markActiveFlowAsCompleted = () => {
    const currentFlowOption = find(flowOptions, ['id', activeFlow.id]) || {};
    currentFlowOption.completed = true;
  };

  const goToNext = () => {
    const calculatedIndex = stepIndex + 1;
    const outMaxBound = calculatedIndex === activeFlow.steps.length;
    // if max bound then no increment
    const newIndex = outMaxBound ? stepIndex : calculatedIndex;
    setIndex(newIndex);

    const isLastStep = calculatedIndex === activeFlow.steps.length - 1;
    if (isLastStep) {
      markActiveFlowAsCompleted();
    }
  };

  const goBack = () => {
    const calculatedIndex = stepIndex - 1;
    const outMinBound = calculatedIndex < 0;
    const newIndex = outMinBound ? stepIndex : calculatedIndex;

    setIndex(newIndex);
  };

  const reset = () => setIndex(0);

  return {
    stepIndex,
    flowOptions,
    activeFlow,
    isLoading: !isReady,
    goToNext,
    goBack,
    reset,
    changeFlow,
  };
}

export default useTour;
