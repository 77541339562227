import hexToRGBA from 'utils/hexToRGBA';

function themecss(theme) {
  const guideColor = hexToRGBA(theme.background);
  const highlightBG = hexToRGBA(theme.background, 0.4);
  const inactiveBG = hexToRGBA(theme.background, 0.1);
  const highlightColor = hexToRGBA(theme.background, 1);
  const divisorColor = hexToRGBA(theme.color);
  return `
  .popover-body {
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSize};
  }
  .hotspot.click {
    background-color: ${inactiveBG};
  }

  .hotspot .guide-icon {
    opacity: 0.1;
  }

  .hotspot .guide-icon.active {
    color: ${theme.color};
    opacity: 1;
  }
  .hotspot-wrapper .hotspot.highlight {
    border: 2px solid ${highlightColor};
    background-color: ${highlightBG};
  }
  .bs-popover-auto[x-placement^=right]>.arrow::after, .bs-popover-right>.arrow::after {
    border-right-color: ${guideColor};
  }
  .bs-popover-auto[x-placement^=left]>.arrow::after, .bs-popover-left>.arrow::after {
    border-left-color: ${guideColor};
  }
  .bs-popover-auto[x-placement^=top]>.arrow::after, .bs-popover-top>.arrow::after {
    border-top-color: ${guideColor};
  }
  .bs-popover-auto[x-placement^=bottom]>.arrow::after, .bs-popover-bottom>.arrow::after {
    border-bottom-color: ${guideColor};
  }

  .popover {
    background-color: ${guideColor};
  }
  .popover-body {
    color: ${theme.color};
  }
  .hotspot-footer {
    border-top: 1px solid ${divisorColor};
  }
  .hotspot-header {
    border-bottom: 1px solid ${divisorColor};
  }`;
}

export default themecss;
