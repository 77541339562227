import ReactGA from 'react-ga';

/** Track Google Analytics event  */
export const trackGAEvent = (action, id, referenceValue = null) => {
  ReactGA.event({
    category: 'Experience',
    action,
    label: id,
    value: referenceValue,
  });
};
