/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import GuidedTourView from 'app/Views/GuidedTour';
import MarvelIframeView from 'app/Views/MarvelIframe';

import experienceTracker from 'app/Services/ExperienceTrackerService';
import ErrorDisplay from './components/ErrorDisplay';
import useLoadExperience from './useLoadExperience';
import { RESULT } from './constants';
import './styles.scss';

function ExperienceView() {
  const { id } = useParams();
  const tourRequest = useLoadExperience(id);

  useEffect(() => {
    experienceTracker.init();
  }, []);

  useEffect(() => {
    if (tourRequest.isReady) {
      experienceTracker.track(id, tourRequest.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourRequest.isReady, tourRequest.data]);

  if (!tourRequest.isReady) {
    return (
      <div className="loading-spinner">
        <Spinner className="text-primary" animation="border" />
      </div>
    );
  }

  if (tourRequest.isReady && tourRequest.result === RESULT.DISABLED) {
    return <ErrorDisplay displayCode={false} errorCode={RESULT.DISABLED} />;
  }

  if (tourRequest.isReady && tourRequest.result !== RESULT.LOADED) {
    const errorCode = get(tourRequest, 'error.code');

    return <ErrorDisplay errorCode={errorCode} />;
  }

  const { type, demo } = tourRequest.data;

  return (
    <div>
      {type === 'GUIDED' ? (
        <GuidedTourView tourData={tourRequest.data} />
      ) : (
        <MarvelIframeView name={demo.name} url={demo.click_demo_url} />
      )}
    </div>
  );
}

export default ExperienceView;
